export const data = {
  yas: [
    {
      name: "Пожарный автомобиль",
      marka: "36222",
      img: "/img/katalog/none.png",
      param: {},
      gallari: [],
    },
    {
      name: "Хлебовоз",
      marka: "290513",
      img: "/img/katalog/1.png",
      param: {},
      gallari: [],
    },
    {
      name: "Автолавка",
      marka: "290513",
      img: "/img/katalog/2.png",
      param: {},
      gallari: [],
    },
    {
      name: "Пожарный автомобиль",
      marka: "362223",
      img: "/img/katalog/3.png",
      param: {},
      gallari: [],
    },
    {
      name: "Молоковоз",
      marka: " 362213",
      img: "/img/katalog/4.png",
      param: {},
      gallari: [],
    },
    {
      name: "Молоковоз",
      marka: "36221",
      img: "/img/katalog/5.png",
      param: {},
      gallari: [],
    },
    {
      name: "Топливозаправщик",
      marka: "36223",
      img: "/img/katalog/6.png",
      param: {},
      gallari: [],
    },
    {
      name: "Топливозаправщик",
      marka: "362233",
      img: "/img/katalog/7.png",
      param: {},
      gallari: [],
    },
    
  ],
  gaz: {},
  pricep: {},
};
export const news=[
  {
    img:"/img/news/gallari/280324/1.jpeg",
    text:"Совместные усилия и профессиональное оборудование играют решающую роль в предупреждении и борьбе с лесными пожарами.",
    textBig: "<span>В феврале 2024г. мы приняли заказ на а/м Пожарный от наших новых партнеров ООО «ТорТехМаш» из г. Торжок. Рады были познакомиться с компетентными коллегами и профессионалами в области производства пожарной и коммунальной техники. </span> <br>"+
    
    "<span>В рамках нашего сотрудничества, 28 марта мы представили наш надежный и универсальный а/м 362223 «Пожарный» на базе УАЗ Профи.</span> <br>"+
    "<span>Данный а/м оснащен необходимым пожарно-техническим вооружением и аварийно-спасательными средствами для выполнения противопожарных работ с помощью огнетушащих веществ или подачи воды. <br>"+
    "<span>Мы уверены, что наш а/м «Пожарный» станет надежным «помощником» в борьбе с пожарами на Рязанской земле, и будет выполнять свою важную миссию с верой и правдой. <br>"+
    "<span>Благодарим ООО «ТорТехМаш» за оказанное доверие! Верим, что это начало пути плодотворного и долгосрочного сотрудничества! <br>"+
    "<span>Мы продолжаем совершенствоваться, стремимся к интересным проектам, открыты к новым деловым знакомствам. <br>"+
    "<span>Следите за нашими новостями, узнавайте первыми о новых проектах, событиях и достижениях компании. <br>"+
    "<span>Спасибо, что остаетесь с нами!<br>",
    data:"28/03/2024",
    imgGallari:[
      "/img/news/gallari/280324/1.jpeg",
      "/img/news/gallari/280324/2.jpeg",
      "/img/news/gallari/280324/3.jpeg",
      "/img/news/gallari/280324/4.jpeg",
      "/img/news/gallari/280324/5.jpeg",
    ],
  },
  {
    img:"/img/news/gallari/160224/1.jpeg",
    text:"В ритме приключений и новых возможностей! ",
    textBig:"<span >Новые приключения Топливозаправщика начались! Топливозаправщик односекционный на базе УАЗ Профи отправился в дорогу, заданное направление - г. Нижний Новгород! </span> <br>"+
    "<span>Этот герой уверенно преодолевает снежные преграды и ледяные дороги, чтобы доставить необходимое топливо в пункт назначения. </span> <br>"+
    
    "<span>Компания   «Спецавто» гордится возможностью предоставлять  только лучшие образцы спецтехники своим заказчикам. И этот Топливозаправщик - отличное подтверждение этого!</span> <br>"+
    "<span>Его универсальная  конструкция позволяет доставлять топливо в любые уголки города, а надежность и комфорт УАЗ Профи делают каждую поездку максимально приятной. <br>"+
    "<span>Так что, друзья, оставайтесь на связи и не пропускайте новые посты о лучших тружениках дороги! <br>"+
    "<span>Встречайте каждый новый день с улыбкой и радостью, ведь приключения уже ждут! <br>"+
    "<span>Спецавтомобиль  от «Спецавто» - ваш верный помощник в любой ситуации! <br>",
    data:"16/02/2024",
    imgGallari:[
      "/img/news/gallari/160224/1.jpeg",
      "/img/news/gallari/160224/2.jpeg",
      "/img/news/gallari/160224/3.jpeg",
      "/img/news/gallari/160224/4.jpeg",
      "/img/news/gallari/160224/5.jpeg",
    ],
  },
  {
    img:"/img/news/gallari/200124/1.jpeg",
    text:"Новые приключения а/м «Водовоз»!",
    textBig:"<span >В преддверии крещенских морозов мы отправили заказчику в Якутию отважного помощника – а/м 36221 «Водовоз» на базе УАЗ СГР.</span> <br>"+
    "<span>Этот автомобиль - настоящий труженик, созданный для работы в самых суровых условиях. Переживет любые морозы и снегопады, ведь в его ДНК - надежность и прочность."+
    "В новом приключении «Водовоз» будет доставлять воду тем, кто в ней нуждается. Якутия - земля, где водный ресурс очень ценится, а «Водовоз» нашел свое призвание - помогать!</span> <br>"+
    
    "<span>Команда ООО «Спецавто» уверена, ребята в Якутии с нетерпением ждут эту машину. Мы гордимся тем, что можем быть частью вашей жизни и помогать решать повседневные потребности.</span> <br>"+
    "<span>Спасибо, что выбираете ООО «Спецавто»!<br>"+
    "<video controls style='max-width:100%;' <source src='/img/news/gallari/200124/v1.mp4' type='video/mp4' /></video> <br>",
    data:"20/01/2024",
    imgGallari:[
      "/img/news/gallari/200124/1.jpeg",
      "/img/news/gallari/200124/2.jpeg",
      "/img/news/gallari/200124/3.jpeg",
      "/img/news/gallari/200124/4.jpeg",
    ],
  },
  {
    img:"/img/news/gallari/1112/1.jpeg",
    text:"С широкой улыбкой хотим поделиться очередной приятной новостью!",
    textBig:"<span >Сегодня 11 декабря 2023г.  мы отправили в Республику Чувашия потрясающий Промтоварный фургон на базе УАЗ Профи!</span> <br>"+
    "<span>Это настоящий 'рабочий конь' с надежным и функциональным фургоном, высокой проходимостью и безупречными характеристиками, который станет надежным помощником в доставке товаров!</span> <br>"+
    
    "<span>Мы гордимся каждым автомобилем, который покидает нашу компанию, потому что мы знаем, что он будет служить и радовать своих владельцев в течение долгих лет.</span> <br>"+
    "<span>Ведь именно для Вас, наши дорогие клиенты, мы постоянно стремимся предлагать только лучшие автомобили, отвечающие всем требованиям и ожиданиям.</span> <br>"+
    "<span>Ваша удовлетворенность – наш главный приоритет!</span> <br>",
    data:"11/12/2023",
    imgGallari:[
      "/img/news/gallari/1112/2.jpeg",
      "/img/news/gallari/1112/3.jpeg",
      "/img/news/gallari/1112/4.jpeg",
      "/img/news/gallari/1112/5.jpeg",
      "/img/news/gallari/1112/6.jpeg",
      "/img/news/gallari/1112/7.jpeg",
      "/img/news/gallari/1112/8.jpeg",
    ],
  },
  {
    img:"/img/news/gallari/0112/5.jpeg",
    text:"Красавец от ООО «Спецавто».Эффект «Вау» обеспечен!",
    textBig:"<span >Посмотрите какой белоснежный красавец - Топливозаправщик мы подготовили и отправили сегодня нашему Заказчику в Армению.</span> <br>"+
    "<span>«Топливозаправщик» предназначен для транспортирования и заправки светлых нефтепродуктов. Автомобиль оборудован топливораздаточной колонкой и откалиброван, т.е. по требованию заказчика цистерна изготовлена как мера вместимости.</span> <br>"+
    
    "<span>Выбирая наш Топливозаправщик (модель 362233 на базе УАЗ-ПРОФИ), вы получаете автомобиль специального назначения, который будет полезным в специфике Вашей деятельности.</span> <br>"+
    "<span>Мы стремимся предоставить нашим Заказчикам только самые лучшие решения. И отправка нашего Топливозаправщика в Армению - это еще одно подтверждение нашей надежности и профессионализма.</span> <br>"+
    "<span>Дорогие наши клиенты, мы хотим выразить вам огромную благодарность за ваше доверие! Без вас мы бы не смогли достичь таких высоких результатов и стать лучшими в своей области. Вы - наша вдохновляющая сила!</span> <br>",
    data:"01/12/2023",
    imgGallari:[
      "/img/news/gallari/0112/1.jpeg",
      "/img/news/gallari/0112/2.jpeg",
      "/img/news/gallari/0112/3.jpeg",
      "/img/news/gallari/0112/4.jpeg",
      "/img/news/gallari/0112/5.jpeg",
      "/img/news/gallari/0112/6.jpeg",
    ],
  },
  {
    img:"/img/news/16.jpg",
    text:"Своих не бросаем!",
    textBig:"<span>Хотим поделиться с вами одной крайне важной новостью. Наша команда ООО «Спецавто» присоединилась к октябрьской гуманитарной помощи и сделала небольшой, но полезный вклад в помощь тем, кто сейчас на передовой. Мы решили поддержать наших мобилизованных ребят, которые защищают безопасность нашей страны, закупив дизельный автономный обогреватель, чтобы организовать тепло и комфорт в этих непростых и тяжелых условиях.</span> <br>"+
    "<span>Сейчас, когда холода становятся сильнее, такая поддержка безусловно важна. Мы не можем остаться равнодушными к нужде наших военнослужащих и считаем, что каждый из нас может и должен внести свой вклад в помощь бойцам. Гуманитарная помощь - это не только финансовая и материальная помощь, но и душевная поддержка. Мы призываем каждого из ВАС задуматься о том, как можно помочь ТЕМ, где сейчас труднее всего. Может быть, это будет небольшой вклад, но любая помощь имеет значение.</span> <br>"+
    
    "<span>Давайте вместе, по мере сил и возможностей, делать добро и помогать тем, кто в этом нуждается.</span> <br>"+
    "<span><b>Вместе мы СИЛА!</b></span> <br>",
    data:"04/10/2023",
    imgGallari:[
      "/img/news/gallari/16.1.jpg",
      "/img/news/gallari/16.2.jpg",
    ],
  },
  {
    img:"/img/news/17.jpg",
    text:"Обеспечиваем топливом для выполнения боевых задач!",
    textBig:"<span>Компания ООО «Спецавто» с гордостью представляет Топливозаправщик, который был поставлен к месту СВО, и мы уверены, что это еще один шаг к Победе!</span><br><span>АТЗ выполнен в зеленом цвете, что позволяет ему быть незаметным в боевых условиях. Мы полностью сохранили его технические характеристики, а проходимость и маневренность этого автомобиля просто бесценны</span><br> <span>Наш топливозаправщик может обеспечивать надежную и безопасную заправку нефтепродуктами в местах боевых действий.</span><br><span>Верим, что он там очень нужен!</span><br><span> Надеемся, что благодаря нашему Топливозаправщику, бойцы будут своевременно обеспечены топливом для успешного выполнения боевых задач!</span><br><span>Выражаем искреннюю благодарность всем нашим клиентам за возможность исполнять такие спецмашины!</span><br><span>Ваше доверие - наша главная цель, а мы, со своей стороны, гарантируем хорошее качество нашей продукции.</span> <br>",
    data:"01/03/2023",
    imgGallari:[
      "/img/news/gallari/2811/1.jpg",
      "/img/news/gallari/2811/2.jpg",
      "/img/news/gallari/2811/3.jpg",
      "/img/news/gallari/2811/4.jpg",
    ],
  },
  {
    img:"/img/news/11.jpg",
    text:"В октябре 2022г. ООО «Спецавто» представило новые машины – «Рыбовозы».",
    textBig:"<span>Автоцистерны для перевозки живой рыбы – это пищевые автоцистерны, дополнительно оборудованные системой обогащения воды кислородом и освобождения от углекислоты и хлора путем продувания воздуха для поддержания жизнеспособности рыбы во время ее транспортировки.</span> <br>"+
    "<span>Автоцистерны-рыбовозы имеют термоизоляцию. Загрузка живой рыбы в цистерну производится через верхний загрузочный люк, выгружается рыба через них же сачками или же через разгрузочный люк в задней стенке цистерны.</span> <br>"+
    
    "<span>Если Вы планируете заниматься перевозкой живой рыбы на длинные и короткие расстояния для продажи или выращивания, то ООО «Спецавто» изготовит для Вас спецавтомобиль «Рыбовоз».</span> <br>",
    data:"03/11/2022",
    imgGallari:[
      "/img/news/11.jpg",
      "/img/news/gallari/11.1.jpg",
      "/img/news/gallari/11.2.jpg",
      "/img/news/gallari/11.3.jpg",
      "/img/news/gallari/11.4.jpg",
    ]
  },
  {
    img:"/img/news/gallari/1.3.jpg",
    text:"На днях наша компания ООО «Спецавто» отправила три Пожарные машины в Казахстан.",
    textBig:"Теперь они верой и правдой будут служить во благо общества!",
    data:"28/06/2021",
    imgGallari:[
      "/img/news/gallari/1.3.jpg",
      "/img/news/5.jpg",
      "/img/news/gallari/1.2.jpg",
      "/img/news/gallari/1.4.jpg",
      "/img/news/gallari/1.5.jpg",
      "/img/news/gallari/1.6.jpg",
      "/img/news/gallari/1.7.jpg",

    ]
  },
  {
    img:"/img/news/14.jpg",
    text:"ООО «Спецавто» предлагает бензовоз в новом исполнении - «Передвижная автозаправочная станция» (ПАЗС).",
    textBig:"<span>Данный автомобиль ПАЗС предназначен для транспортирования и дозированного отпуска светлых нефтепродуктов: бензин, дизельное топливо, керосин (плотностью не более 0,86 г/см3).</span> <br>"+
    "<span>Отпуск топлива осуществляется по лимитным схемам с идентификацией получателя топлива, с использованием пластиковых карт (бесконтактная карта Mifare или Em-Marine), с возможностью объединения карт в группы, с организацией контрольных форм.</span> <br>"+
    "<span>Автомобиль может быть выполнен на базе автомобилей УАЗ-236022, ГАЗ-3302, ГАЗ-А21R23, ГАЗ-А21R22.</span> <br>"+
    "<span>Автомобиль может быть выполнен на базе автомобилей УАЗ-236022, ГАЗ-3302, ГАЗ-А21R23, ГАЗ-А21R22.</span> <br>"+
    "<span>Вот несколько преимуществ а/м  «ПАЗС»:</span> <br>"+
    "<ul>"+
    "<li>быстрое развертывание в удаленных районах</li>"+
    "<li>точная выдача топлива без перелива и недолива с автоматическим учетом каждой заправки</li>"+
    "<li>удаленный контроль за количеством топлива</li>"+
    "</ul>"+
    "<span>Подобрать комплектацию, ознакомиться с ценами и сделать заказ Вы можете, обратившись в нашу организацию.</span> <br>",

    
    data:" 25/06/2021",
    imgGallari:[
      "/img/news/14.jpg",
      "/img/news/gallari/14.1.jpg",
      "/img/news/gallari/14.2.jpg",
      "/img/news/gallari/14.3.jpg",
      "/img/news/gallari/14.4.jpg",
    ]
  },
  {
    img:"/img/news/gallari/2.7.jpg",
    text:"Дезинфектор на базе УАЗ Профи",
    textBig:"<span> И вновь мы готовы представить новый а/м 362243 «Дезинфекционная установка» на базе УАЗ ПРОФИ.</span>  <br/>"+

    "<span>Такой а/м необходим не только при проведении ветеринарно-санитарных мероприятий в животноводческих помещениях, дезинфекции на складах, в зернохранилищах и овощехранилищах, но и для химической защиты садов, виноградников, ягодников от вредителей и болезней.</span>  <br/>"+
    
    "<span> А еще такая установка может использоваться для тушения небольших очагов возгорания и помывки сельхозтехники.</span> ",
    data:"03/07/2020",
    imgGallari:[
      "/img/news/gallari/2.7.jpg",

      "/img/news/gallari/2.2.jpg",
      "/img/news/gallari/2.3.jpg",
      "/img/news/gallari/2.4.jpg",
      "/img/news/gallari/2.5.jpg",
      "/img/news/gallari/2.6.jpg",
      "/img/news/2.jpg",


    ]
  },
  
  {
    img:"/img/news/3.jpg",
    text:"Участие в ПРОГРАММЕ VII Международного Форума-выставки производителей товаров и услуг",
    textBig:'23-25 апреля 2021г. ООО "Спецавто" приняло участие в выставке-форуме "Сделано в Ульяновской области - 2021". Это стало отличной возможностью найти партнеров и заказчиков, в том числе из других регионов и стран. А также познакомить жителей региона со своей продукцией и повысить узнаваемость бренда.</span> ',
    data:"03/06/2021",
    imgGallari:[
      "/img/news/3.jpg",
      "/img/news/gallari/3.1.jpg",
      "/img/news/gallari/3.2.jpg",
      "/img/news/gallari/3.3.jpg",
      "/img/news/gallari/3.4.jpg",
    ]
  },
  {
    img:"/img/news/12.jpg",
    text:"ООО «Спецавто» представляет новую модель Пожарной машины на базе ГАЗель Next",
    textBig:"<span>Этим летом разработан и произведен пожарный автомобиль на базе ГАЗель Next для выполнения аварийно-спасательных работ, связанных с тушением пожаров. Наш Пожарный автомобиль впервые выпущен с уникальной системой поддержания свинцовых аккумуляторов в оперативной готовности – «Капель-2» .</span> <br>"+
    "<span>Система позволяет непосредственно на автотранспорте:</span> <br>"+
    "<ul>"+
    "<li>заряжать новую АКБ</li>"+
    "<li>поддерживать АКБ автотранспорта в состоянии постоянной готовности к применению.</li>"+
    "</ul>",
    
    data:"10/09/2019",
    imgGallari:[
      "/img/news/12.jpg",
      "/img/news/gallari/12.1.jpg",
      "/img/news/gallari/12.2.jpg",
      "/img/news/gallari/12.3.jpg",
      "/img/news/gallari/12.4.jpg",
      "/img/news/gallari/12.5.jpg",
      "/img/news/gallari/12.6.jpg",
      "/img/news/gallari/12.7.jpg",
    ]
  },
  {
    img:"/img/news/13.jpg",
    text:"Топливозаправщик на базе УАЗ Профи.",
    textBig:"<span>На сегодняшний день линейка автомобилей для перевозки светлых нефтепродуктов пополнилась новыми моделями на базе УАЗ-ПРОФИ. Новые версии коммерческого автомобиля отличаются комплектацией и исполнением цистерны.</span> <br>"+
    "<span>Ключевыми преимуществами новых версий «Топливозаправщика» являются грузоподъёмность, комфорт легкового автомобиля и, конечно же, проходимость на уровне других моделей УАЗ. Бензовозы по габаритам не отличаются от базовой версии. Общий объём автоцистерны 1500 литров. Обе модификации доступны как в полноприводном, так и в заднеприводном исполнениях.</span> <br>",
    
    data:"05/07/2019",
    imgGallari:[
      "/img/news/13.jpg",
      "/img/news/gallari/13.1.jpg",
      "/img/news/gallari/13.2.jpg",
      "/img/news/gallari/13.3.jpg",
      "/img/news/gallari/13.4.jpg",
    ]
  },

]